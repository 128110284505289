import React from "react";
import { MaxWidth } from "./styled";

interface IProps {
    children: React.ReactNode;
}

const MaxWidthContainer: React.FC<IProps> = props => {
    return <MaxWidth>{props.children}</MaxWidth>;
};

export default MaxWidthContainer;
