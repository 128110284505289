import styled from "styled-components";
import { media } from "../../../utils/media";

export const Section = styled.section`
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    margin: 110px 50px 100px;

    ${media.tablet`
        margin: 200px 120px;
        flex-direction: row-reverse;
    `}

    ${media.desktop`
      margin: 200px auto 100px;
      align-items: center;
      height: auto;
      max-width: 1560px;
      padding: 0 180px;
      box-sizing: content-box;
      justify-content: space-around;
      flex-direction: row;
    `}
`;

export const Title = styled.h1`
    margin: 0;
    font-size: 2.5em;
    line-height: 4rem;
    color: ${({ theme }) => theme.colors.blue};
    font-family: ${({ theme }) => theme.fonts.verdana};
    position: relative;

    ${media.tablet`
        font-size: 3.5em;
        line-height: 5.2rem;
    `}
`;

export const SubTitle = styled.h5`
    margin: 0;
    font-size: 1.1rem;
    line-height: 3rem;
    letter-spacing: 1px;
    font-family: ${({ theme }) => theme.fonts.openSans};
    text-transform: uppercase;
    font-weight: 500;

    color: ${({ theme }) => theme.colors.orange};
`;

export const AboutContent = styled.p`
    display: block;
    max-width: 100%;
    margin: 20px 0 0;

    font-size: 1.5rem;
    line-height: 3rem;
    font-family: ${({ theme }) => theme.fonts.openSans};

    color: ${({ theme }) => theme.colors.gray};
`;

export const TextWrap = styled.div`
    position: relative;
    max-width: 490px;

    ${media.tablet`
        transform: translate(0%, -30%);
    `}
    ${media.desktop`
        transform: none;
    `}
`;

export const SecondaryButton = styled.button`
    border: 1px ${({ theme }) => theme.colors.orange} solid;
    border-radius: 2px;
    cursor: pointer;

    transition: all 100ms linear;

    color: ${({ theme }) => theme.colors.blue};
    background-color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.fonts.openSans};
    font-size: 1.4rem;

    margin: 1.1em 0 0 0;
    padding: 1em 2.8em;

    &:hover {
        color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme }) => theme.colors.orange};
    }
`;

export const ImageWrap = styled.div`
    position: relative;
    width: 100%;

    ${media.tablet`
        transform: translate(0%, 20%);
        max-width: 515px;
    `}

    ${media.desktop`
        transform: translate(-10%, 0%);
        align-self: auto;
        max-width: 650px;
    `}
`;

interface IUnderlayProps {
    underlayColor: string;
}

export const UnderlayWrap = styled.div`
    position: relative;
    z-index: -1;
    width: 100%;
    height: 400px;

    ${media.desktop`
      width: 650px;
      height: 650px;
    `}
`;

export const ImageUnderlay = styled.div<IUnderlayProps>`
    width: 120px;
    height: 120px;
    z-index: -1;
    background-color: ${props => props.underlayColor};
    position: absolute;

    transform: translate(420px, 280px) rotate(45deg);

    ${media.tablet`
        transform: translate(300px, 300px) rotate(45deg);
    `}

    ${media.desktop`
        transform: translate(370px, 420px) rotate(45deg);
    `}

    ${media.bigDesktop`
        transform: translate(470px, 450px) rotate(45deg);
    `}
`;

export const ImageBig = styled.img`
    position: absolute;
    z-index: 1;
    width: auto;
    height: 100%;

    transform: translate(25%, 0%);

    ${media.tablet`
        transform: translate(-10%, 0%);
    `}

    ${media.desktop`
        height: 70%;
        transform: translate(0%, 100px);
    `}

    ${media.bigDesktop`
        height: 85%;
        transform: translate(0%, 50px);
    `}
`;

export const ImageSmall = styled.img`
    height: 40%;
    width: auto;
    z-index: 1;
    position: absolute;

    transform: translate(400px, -10%);

    ${media.tablet`
        transform: translate(300px, -15%);
    `}

    ${media.desktop`
        height: 30%;
        transform: translate(330px, 50px);
    `}

    ${media.bigDesktop`
        height: 40%;
        transform: translate(400px, -15%);
    `}
`;
