import React from "react";
import { Link } from "gatsby";

import {
    Section,
    TextWrap,
    UnderlayWrap,
    ImageWrap,
    ImageUnderlay,
    ImageBig,
    ImageSmall,
    Title,
    SubTitle,
    SecondaryButton,
} from "./styled";

interface IProps {
    title: string;
    subTitle: string;
    bigImageUrl: string;
    smallImageUrl: string;
    underlayColor: string;
    id: string;
}

const BigImageSection: React.FC<IProps> = ({
    title,
    subTitle,
    bigImageUrl,
    smallImageUrl,
    underlayColor,
    id,
}) => {
    return (
        <Section id={id}>
            <ImageWrap data-aos="fade-up" data-aos-delay="300">
                <ImageBig src={bigImageUrl} alt="big image patria" />
                <ImageSmall src={smallImageUrl} alt="small image patria" />
                <UnderlayWrap>
                    <ImageUnderlay underlayColor={underlayColor} />
                </UnderlayWrap>
            </ImageWrap>
            <TextWrap>
                <Title data-aos="fade-up" data-aos-delay="200">
                    {title}
                </Title>
                <Link to="/slovnik" data-aos="fade-up" data-aos-delay="200">
                    <SecondaryButton>Přečtěte si více</SecondaryButton>
                </Link>
            </TextWrap>
        </Section>
    );
};

export default BigImageSection;
