import React from "react";
import { SecondaryButton } from "../BigImageSection/styled";

import {
    Section,
    TextWrap,
    UnderlayWrap,
    ImageWrap,
    ImageUnderlay,
    Image,
    Title,
    AboutContent,
} from "./styled";

interface IProps {
    title: string;
    paragraph: string;
    imageUrl: string;
    underlayColor: string;
    id: string;

    buttonText?: string;
    onClick?: () => void;
}

const AboutSection: React.FC<IProps> = ({
    title,
    paragraph,
    imageUrl,
    underlayColor,
    id,
    buttonText,
    onClick,
}) => {
    return (
        <Section id={id}>
            <TextWrap>
                <Title data-aos="fade-up" data-aos-delay="200">
                    {title}
                </Title>
                <AboutContent data-aos="fade-up" data-aos-delay="300">
                    {paragraph}
                </AboutContent>
                {buttonText && (
                    <div data-aos="fade-up" data-aos-delay="300">
                        <SecondaryButton
                            onClick={onClick && onClick}
                            style={{ marginTop: "2rem" }}
                        >
                            {buttonText}
                        </SecondaryButton>
                    </div>
                )}
            </TextWrap>

            <ImageWrap>
                <Image
                    src={imageUrl}
                    alt="about patria image"
                    data-aos="fade-up"
                    data-aos-delay="200"
                    data-aos-duration="800"
                />
                <UnderlayWrap data-aos="fade-up" data-aos-delay="200">
                    <ImageUnderlay underlayColor={underlayColor} />
                </UnderlayWrap>
            </ImageWrap>
        </Section>
    );
};

export default AboutSection;
