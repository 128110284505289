import styled from "styled-components";
import { media } from "../../../utils/media";

export const Section = styled.section`
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 110px 50px 0;

    ${media.tablet`
        margin: 150px 120px;
    `}

    ${media.desktop`
        margin: 0 auto;
        align-items: center;
        height: auto;
        max-width: 1560px;
        padding: 0 180px;
        box-sizing: content-box;
        justify-content: space-between;
        flex-direction: row;
    `}
`;

export const Title = styled.h1`
    margin: 0;
    font-size: 2.5em;
    line-height: 4rem;
    color: ${({ theme }) => theme.colors.blue};
    font-family: ${({ theme }) => theme.fonts.verdana};
    position: relative;

    :before {
        content: "";
        position: absolute;
        left: 0;
        height: calc(100% - 1.8rem);
        margin-top: 0.9rem;
        width: 5px;
        background-color: ${({ theme }) => theme.colors.orange};
        padding: 0;
        left: -30px;
    }

    ${media.tablet`
        font-size: 3.5em;
        line-height: 5.2rem;
    `}
`;

export const AboutContent = styled.p`
    display: block;
    max-width: 100%;
    margin: 30px 0 0;

    font-size: 1.5rem;
    line-height: 3rem;
    font-family: ${({ theme }) => theme.fonts.openSans};

    color: ${({ theme }) => theme.colors.gray};
`;
export const TextWrap = styled.div`
    max-width: 490px;
    position: relative;
`;

export const StyledPrimaryButton = styled.button`
    width: 100%;

    margin-bottom: 75px;
`;

export const ImageWrap = styled.div`
    position: relative;
    width: auto;
    align-self: flex-end;
    transform: translate(30%, 10%);
    ${media.tablet`
        width: 100%;
        transform: translate(50%, -10%);
    `}

    ${media.desktop`
        align-self: auto;
        transform: none;
        max-width: 515px;
        transform: translate(20%, 0%) scale(0.8);
    `}

    ${media.bigDesktop`
        transform: scale(1);
    `}
`;

interface IUnderlayProps {
    underlayColor: string;
}

export const UnderlayWrap = styled.div`
    z-index: -1;
    width: 300px;
    height: 300px;

    ${media.tablet`
        width: 550px;
        height: 550px;
    `}
`;

export const ImageUnderlay = styled.div<IUnderlayProps>`
    width: 100%;
    height: 100%;
    z-index: -1;
    transform: rotate(45deg) scale(0.71);
    background-color: ${props => props.underlayColor};
`;

export const Image = styled.img`
    left: -20px;
    position: absolute;
    height: 100%;
    width: auto;
    z-index: 1;
`;
