import styled from "styled-components";

export const MaxWidth = styled.div`
    max-width: 1560px;
    overflow: hidden;
    margin: 100px auto 0;

    & > section:first-child {
        margin-top: 50px;
    }
`;
